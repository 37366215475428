.popup {
	display: flex;
	position: fixed;
	width: 100vw;
	height: 80%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	justify-content: center;
	align-items: center;
}

.input-block {
	padding: 20px;
	width: 550px;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: white;
}

.input-block > p, h5 {
	margin: 0;
}

.popup-header {
	margin-bottom: 15px;
	font-size: 22px;
}

.example {
	padding-bottom: 20px;
}

.emails-textarea {
	width: 60%;
	height: 80px;
	margin-bottom: 20px;
}

.share-button {
	margin-right: 20px;
}