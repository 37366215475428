.rs-modal-content {
    min-height: auto !important;
}

.rs-modal-body {
    display: flex;
    gap: 20px;
}

.event-card .rs-modal {
    margin: 20% auto 0 auto !important; 
}