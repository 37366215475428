.social-icon-container {
    height: 20px;
    box-sizing: border-box;
}

.h-icon-svg{
    height: 20px !important;
    width: auto !important;
}

.links-container svg{
    height: 32px;
}

.h-social-icons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.footer-img {
    max-width: 60%;
}

.footer-img img {
    width: 100%;
}

footer {
    display: flex;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
}

.footer-container {
    display: flex;
    align-items: center;
    padding: 50px 0;
    justify-content: space-around;
}

@media screen and (max-width: 900px) {    
    .h-icon-svg{
        height: 20px !important;
        width: 20px !important;
    }
    
    .links-container svg{
        height: 20px;
    }
}

@media screen and (max-width: 600px) {
    .h-social-icons {
        gap: 15px;
    }

    .h-icon-svg{
        height: 20px !important;
        width: 20px !important;
    }
    
    .links-container svg{
        height: 20px;
    }
}

@media screen and (max-width: 450px) {
    .h-social-icons {
        gap: 10px;
    }

    .h-icon-svg{
        height: 15px !important;
        width: 15px !important;
    }
    
    .links-container svg{
        height: 15px;
    }
}