@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');


.header {
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    z-index: 10;
    position: fixed;
    width: 100%;
    background-color: #ffffff;
  }

  .header-gap {
    height: 180px;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: center;
    color: rgb(2, 110, 159);
    font-family: 'Open Sans';
    font-weight: 600;
    top: 0;

    background-image: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 1200px;
    margin: 0 auto;
    
  }
  

.menu-items-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.mobile-container {
    display: none;
    width: 34px;
    height: 34px;
    background-color: #e5e5e5;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.mobile-container svg path{
   fill: #fff;
}

.mobile-container svg{
    height: 24px;
 }

header .logo {
    padding: 15px 30px;
}

header .logo img{
    width: 300px;
}

.menu-item {
    cursor: pointer;
}
.menu-item a,
.menu-item.active a{
    text-decoration: none;
    color: rgb(2, 110, 159);
    transition: 0.3s;
    position: relative;
    font-family: 'Open Sans', sans-serif;
  }
  
.menu-item.active a,
.menu-item a:hover {
    color: #03a9f4;
}

.menu-item a::after{
    display: block;
    background-color: #03a9f4;
    background-image: none;
    height: 3px;
    bottom: -2px;
    width: 0%;
    content: '';
    position: absolute;
    transition: 0.3s;
}

.menu-item.active a::after ,
.menu-item:hover > a::after {
    display: block;
    background-color: #03a9f4;
    background-image: none;
    height: 3px;
    bottom: -2px;
    width: 100%;
    content: '';
    position: absolute;
}

.menu-item a:hover{
    color: #03a9f4;
}


.mobile-menu {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    background-color: #222B34;
    background-image: none;
    width: 300px !important;
    align-items: center;
    top: 0;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
    right: -300px;
    z-index: 1000;
}

.menu-shadow {

}

.mobile-menu.active {
    right: 0;
}
 
.mobile-logo img{
    padding: 10px;
    width: 165px;
    margin-bottom: 10px;
}

.menu-header-menu{
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
  
.menu-item-mobile a{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    border-top-width: 0px;
    border-top-color: #808080;
    border-top-style: solid;
    border-right-width: 0px;
    border-right-color: #808080;
    border-right-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #808080;
    border-bottom-style: solid;
    border-left-width: 0px;
    border-left-color: #808080;
    border-left-style: solid;
    font-size: 14px;
    color: white;
    text-decoration: none;
    height: 46px;
    display: inline-block;
    width: 100%;
    font-family: 'Open Sans';
    font-weight: 500;
}

.menu-item-mobile {
    display: flex;
}

.menu-shadow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    background-image: none;
    z-index: 999;
    display: none;
}

.menu-shadow.active {
    display: block;
}

@media screen and (max-width: 1199px) {
   
    .menu-items-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
        flex-wrap: wrap;
    }

    .mobile-menu {
        display: none;
    }

    
}

@media screen and (max-width: 1000px) {
   
    .menu-items-container {
        display: none;

    }

    .mobile-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .header-container {
        justify-content: space-around;
        width: calc(100vw - 32px);
    }

    .mobile-menu {
        display: flex;
    }

    
}

@media screen and (max-width: 460px) {

    .header-container {
        justify-content: space-between;
        width: calc(100% - 32px);
    }
    
    header .logo {
        padding: 0;
    }

}